import React from "react";
import "./BSIT.css";

function BSIT() {
  return (
    <div className="BSIT-container">
      <div className="main-banner">
        <h1>Bachelor of Science in Information Technology <br/>(BSIT)</h1>
      </div>

      <div className="sub-content">
        <h2>Program Information</h2>
        <p>
          The Bachelor of Science in Information Technology (BSIT) is a
          comprehensive four-year program designed to equip students with a
          robust understanding of information technology concepts and
          applications. Throughout the curriculum, students engage in courses
          that cover a broad spectrum of IT areas, including Systems Analysis,
          Networking, Database Management, and Software Development. The program
          emphasizes a balance between theoretical knowledge and practical
          skills, fostering competencies in areas such as IT project management,
          network administration, and programming. Students often have the
          opportunity to specialize in areas like Cybersecurity, Cloud
          Computing, or Web Development through elective courses. The BSIT
          program prepares graduates for a variety of roles in the IT industry,
          such as systems analysis, network administration, and software
          development. With a focus on emerging technologies and industry
          trends, students are well-positioned to adapt to the dynamic IT
          landscape. The program typically culminates in a capstone project
          where students apply their skills to solve real-world IT challenges.
          Upon completion, BSIT graduates are ready for entry into the workforce
          or may choose to pursue advanced studies in specialized IT fields.
        </p>
      </div>
      <div className="sub-content">
        <h2>Eligibility Criteria</h2>
        <ul>
        <li>
          Minimum 50% marks in Intermediate/12 years schooling/A- Level (HSSC) or Equivalent with
          Mathematics are required for admission in BS Information Technology Program. Equivalency
          certificate by IBCC will be required in case of education from some other country or system.
          </li>
          <li>
          FSc pre-medical students are also eligible but the students have to pass deficiency courses
          (Pre-Calculus-I and Pre-Calculus-II / Equivalent). These deficiency mathematics courses are the
          prerequisite of other mathematical courses to ensure that the students have completed their
          deficiency in first year of their studies. Additionally, courses will be considered as non-
          credited courses.
          </li>
          <li>
          At minimum 133 credit hours are required for award of BSIT degree.
          </li>
          <li>
          The minimum duration for completion of BSIT degrees is four years. The maximum period of
          degree completion will be followed as per University of Education Lahore policy.
          </li>
          <li>
          A minimum 2.0 CGPA (Cumulative Grade Point Average) on a scale of 4.0 is required for award
          of BS Information Technology Degree.
          </li>
          <li>
          After successfully completing 04 semesters in the BSIT program, students may exit with an
          Associate Degree in Information Technology, subject to meeting all requirements for the
          award of the associate degree. These requirements include fulfilling the required Credit
          Hours, achieving a minimum CGPA, and completing compulsory courses or as per the
          approved university policy. However, students must complete a minimum of 72 credits to be
          eligible for the Associate Degree.
          </li>
        </ul>
      </div>
      <div className="sub-content">
        <h2>Award of Degree</h2>
        <p>
          For the award of BS Information Technology degree, a student must
          have:
        </p>
        <ul>
          <li>
            Passed courses totalling at least 133 credit hours, including all
            those courses which have been specified as Core courses.
          </li>
          <li>Obtained a CGPA of at least 2.00</li>
        </ul>
      </div>
      <div className="sub-content">
        <h2>Why BSIT at LSIT?</h2>
        <ul>
          <li>Broad and in Depth Latest Knowledge of Concepts.</li>
          <li>Provides Industry Relevant Skills.</li>
          <li>Hands on Learning.</li>
          <li>Provides Career Opportunities at entry level.</li>
          <li>Highly Experienced Faculty.</li>
        </ul>
      </div>
      <div className="button">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfDCFhTHjuw2zEW8uQ9B3F7MBg4dJ3zsl0c3VYSlYb9_XbPjg/viewform?usp=sf_link" target="blank"><button>Apply Now</button></a>
      </div>
    </div>
  );
}

export default BSIT;
