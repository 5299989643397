import React from "react";
import "./BBA.css";

function BBA() {
  return (
    <div className="BBA-container">
      <div className="main-banner">
        <h1>Bachelor of Business Administration <br/>(BBA)</h1>
      </div>

      <div className="sub-content">
        <h2>Program Information</h2>
        <p>
          The Bachelor of Business Administration (BBA) is a comprehensive
          four-year program designed to provide students with a strong
          foundation in business and management principles. Throughout the
          curriculum, students engage with courses covering various business
          disciplines, including Marketing, Finance, Human Resources, and
          Operations Management. The program aims to develop a well-rounded
          understanding of business strategies, ethical decision-making, and
          effective communication skills. Students also have the opportunity to
          gain practical experience through internships or cooperative education
          programs. The BBA program prepares graduates for a diverse range of
          careers in business, including roles in corporate management,
          entrepreneurship, marketing, and finance. The interdisciplinary nature
          of the program allows students to explore their specific areas of
          interest within the broader business landscape. Upon completion, BBA
          graduates are equipped with the knowledge and skills necessary to
          navigate the complexities of the business world or pursue advanced
          studies in business-related disciplines.
        </p>
      </div>
      <div className="sub-content">
        <h2>Eligibility Criteria</h2>
        <ul>
          <li>
          Students having Intermediate certification like I. Com, F.A, F.Sc or equivalent (minimum
          2nd Division) are eligible to apply for this program.
          </li>
        </ul>
      </div>
      <div className="sub-content">
        <h2>Award of Degree</h2>
        <p>
          For the award of BBA degree, a student must have:
        </p>
        <ul>
          <li>
            Passed courses totalling at least 130 credit hours, including all
            those courses which have been specified as Core courses.
          </li>
          <li>Obtained a CGPA of at least 2.00</li>
        </ul>
      </div>
      <div className="sub-content">
        <h2>Why BBA at LSIT?</h2>
        <ul>
          <li>Prepare Future Business Leaders.</li>
          <li>Develop Abilities of Analytic & Creative skill.</li>
          <li>Understand the Importance of Teamwork & Group Dynamic.</li>
          <li>Provides Career Opportunities at entry level.</li>
          <li>Develop Dynamic Research Skills.</li>
          <li>Diverse Business Education.</li>
          <li>Bridges Gap between Academia & Industry.</li>
        </ul>
      </div>
      <div className="button">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfDCFhTHjuw2zEW8uQ9B3F7MBg4dJ3zsl0c3VYSlYb9_XbPjg/viewform?usp=sf_link" target="blank"><button>Apply Now</button></a>
      </div>
    </div>
  );
}

export default BBA;
