import React from 'react'
import './Home.css'
import NavBar from './NavBar'
import ImgSlider from './ImgSlider'
import ChairmanMsg from './ChairmanMsg';
import WhyLsit from './WhyLsit';
import Banner from './Banner';
import AboutLsit from './AboutLsit';
import OurCommunity from './OurCommunity';
import Footer from './Footer';
function Home() {
  return (
    <>
      <NavBar />
      <ImgSlider />
      <WhyLsit />
      <Banner />
      <ChairmanMsg />
      <AboutLsit />
      <OurCommunity />
      <Footer />
    </>
  )
}

export default Home
