import React from 'react';
import './Banner.css';
import BS_IT from '../../Assets/bs_information_technology.webp';
import BS_CS from '../../Assets/bs-computer-science.webp';
import BS_BBA from '../../Assets/bs_business-administration.webp';
import BS_Psychology from '../../Assets/BS-Psychology.jpg';
import ADP_CS from '../../Assets/ADP-CS.jpg'
import ADP_IT from '../../Assets/ADP-IT.jpg'
import ADP_BBA from '../../Assets/ADP-BBA.jpg'
import ADP_Psychology from '../../Assets/ADP-Psychology.jpg'
import Post_ADP_CS from '../../Assets/post-ADP-CS.jpg'
import Post_ADP_IT from '../../Assets/post-ADP-IT.jpg'
import Post_ADP_BBA from '../../Assets/post-ADP-BBA.jpg'
import Post_ADP_Psychology from '../../Assets/post-ADP-Psychology.jpg'
import FSC_Medical from '../../Assets/fsc medical.jpg'
import FSC_Eng from '../../Assets/fsc engineering.jpg'
import ICS from '../../Assets/ICS.jpg'
import ICOM from '../../Assets/ICOM.jpg'
import FA from '../../Assets/FA.jpg'
import FA_IT from '../../Assets/FA-IT.jpg'
import D1 from '../../Assets/Commerce-D.jpg'
import D2 from '../../Assets/Nutrition-D.jpg'
import D3 from '../../Assets/Tourism-D.jpg'
import D4 from '../../Assets/Banking-D.jpg'
import D5 from '../../Assets/Culinary-D.jpg'
import D6 from '../../Assets/Hotels-D.jpg'
import D7 from '../../Assets/Lab-D.jpg'


function Banner() {
  return (
    <>
    <div className="banner-container">
      <h2 className="main-heading">Undergraduate Programs</h2>
      <div className="banner-column">
      <div className="banner">
        <div className="program-container">
          <img src={BS_CS} alt="Program 1" />
          <h3>BS Computer Science</h3>
          <a href="/bscs-details">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={BS_IT} alt="Program 2" />
          <h3>BS Information Technology</h3>
          <a href="/bsit-details">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={BS_BBA} alt="Program 3" />
          <h3>Bachelor of Business Administration</h3>
          <a href="/bba-details">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={BS_Psychology} alt="Program 4" />
          <h3>BS Psychology</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={ADP_CS} alt="Program 5" />
          <h3>ADP - CS</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={ADP_IT} alt="Program 6" />
          <h3>ADP - IT</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={ADP_BBA} alt="Program 7" />
          <h3>ADP - BBA</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={ADP_Psychology} alt="Program 8" />
          <h3>ADP - Psychology</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={Post_ADP_CS} alt="Program 9" />
          <h3>Post ADP - CS</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={Post_ADP_IT} alt="Program 10" />
          <h3>Post ADP - IT</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={Post_ADP_BBA} alt="Program 11" />
          <h3>Post ADP - BBA</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={Post_ADP_Psychology} alt="Program 12" />
          <h3>Post ADP - Psychology</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      </div>    
    </div>


    <div className="banner-container">
      <h2 className="main-heading">Intermediate Programs</h2>
      <div className="banner-column">
      <div className="banner">
        <div className="program-container">
          <img src={FSC_Medical} alt="Program 1" />
          <h3>FSC Pre Medical</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={FSC_Eng} alt="Program 2" />
          <h3>FSC Pre Engineering</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={ICS} alt="Program 3" />
          <h3>ICS</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={ICOM} alt="Program 4" />
          <h3>ICOM</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={FA} alt="Program 5" />
          <h3>FA</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={FA_IT} alt="Program 6" />
          <h3>FA IT</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      </div>
    </div>

    <div className="banner-container">
      <h2 className="main-heading">Two Years Diploma Programs</h2>
      <div className="banner-column">
      <div className="banner">
        <div className="program-container">
          <img src={D1} alt="Program 1" />
          <h3>Diploma in Commerce</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={D2} alt="Program 2" />
          <h3>Diploma in Nutrition Sciences</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={D3} alt="Program 3" />
          <h3>Diploma in Tourism</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={D4} alt="Program 4" />
          <h3>Diploma in Professional Banking</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={D5} alt="Program 5" />
          <h3>Diploma in Culinary Arts</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={D6} alt="Program 6" />
          <h3>Diploma in Hotel Operations</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      <div className="banner">
        <div className="program-container">
          <img src={D7} alt="Program 7" />
          <h3>Medical Lab Technician (MLT)</h3>
          <a href="#">
            <button>More Details</button>
          </a>
        </div>
      </div>

      </div>
    </div>
    </>
  );
}

export default Banner;
