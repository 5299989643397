import './OurCommunity.css'
import image1 from '../../Assets/community-image-1.webp';
import image2 from '../../Assets/community-image-2.webp';
import image3 from '../../Assets/community-image-3.webp';
import image4 from '../../Assets/community-image-4.webp';
import image5 from '../../Assets/community-image-5.webp';

function OurCommunity() {
  return (
    <div className="our-community-container">

    <h1 className="community-title">Our Community</h1>

    <div className="community-images-container">
      <div className="community-column">
        <a href="https://www.instagram.com/lsituoe/?next=%2F" target='blank'>
          <img src={image1} alt="Single Community Member" />
        </a>
      </div>

      <div className="community-column">
        
        <div className="community-row">
        <a href="https://www.instagram.com/lsituoe/?next=%2F" target='blank'>
          <img src={image2} alt="Community Member 1" />
        </a>
        <a href="https://www.instagram.com/lsituoe/?next=%2F" target='blank'>
          <img src={image3} alt="Community Member 2" />
        </a>
        </div>

        <div className="community-row">
        <a href="https://www.instagram.com/lsituoe/?next=%2F" target='blank'>
          <img src={image4} alt="Community Member 3" />
        </a>
        <a href="https://www.instagram.com/lsituoe/?next=%2F" target='blank'>
          <img src={image5} alt="Community Member 4" />
        </a>
        </div>
      </div>

    </div>
  </div>
  )
}

export default OurCommunity
