import React from 'react'
import './Scholarships.css'

function Scholarships() {
  return (
    <div className='scholarship-container'>
      <div className="scholarship-main-banner">
        <h1>Scholarship Details</h1>
      </div>

      <div className="scholarship-sub-content">
        <h2>Merit-Based Scholarship</h2>
        <p>To award students with an outstanding academic record, The Lahore School of Innovation and Technology offers up to 100% Merit-Based Scholarship.</p>
      </div>

      <div className="scholarship-sub-content">
        <h2>Need-Based Scholarship</h2>
        <p>Lahore School of Innovation and Technology offers up to 100% Need-Based Scholarships to deserving students.</p>
      </div>
      
      <div className="scholarship-sub-content">
        <h2>Early-Bird Scholarship</h2>
        <p>Lahore School of Innovation and Technology offers early bird scholarships on a first come first serve basis.</p>
      </div>
    </div>
  )
}

export default Scholarships
