import "./AcademicFaculty.css";

function AcademicFaculty({ facultyMembers }) {
    const navigateToDetails = (url) => {
      window.location.href = `/${url}`;  
    };

  const renderFacultyMembers = (department) => {
    return facultyMembers
      .filter((member) => member.facultyDepartment === department)
      .map((member) => (
        <div className="academic-faculty-card" key={member.id}>
          <img src={member.image} alt={member.name} />
          <h3>{member.name}</h3>
          <p>{member.position}</p>
          <button onClick={() => navigateToDetails(member.url)}>
            View Profile
          </button>
        </div>
      ));
  };

  return (
    <div className="academic-faculty-container">
      <div className="academic-faculty-section">
        <h2>Academic Faculty</h2>
        <div className="academic-faculty-row">
          {renderFacultyMembers("Academic Faculty")}
        </div>
      </div>
    </div>
  );
}

export default AcademicFaculty;
