import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import BSCS from "./Components/Pages/BSCS";
import BSIT from "./Components/Pages/BSIT";
import BBA from "./Components/Pages/BBA";
import Scholarships from "./Components/Pages/Scholarships";
import AdministrativeStaff from "./Components/Pages/AdministrativeStaff";
import AcademicFaculty from "./Components/Pages/AcademicFaculty";
import About from "./Components/Pages/About";
import FacultyDetails from "./Components/Pages/FacultyDetails";

import shahzad_image from "./Assets/shahzad-image.webp";
import humaira_image from "./Assets/humaira-image.webp";
import ahsan_image from './Assets/ahsan.webp';
import aliJawad_image from './Assets/alijawad.webp';
import umer_image from "./Assets/umer1.webp";
import ammad_image from "./Assets/ammad-image.webp";
import haider_image from "./Assets/haideralikhan.webp";
import zeeshan_image from "./Assets/zeeshan-image.webp";
import bilal_image from './Assets/bilal.webp';
import rajja_image from "./Assets/rajja1.webp";
import dua_image from './Assets/dua.webp';
import asma_image from "./Assets/asma1.webp";
import ahmad_image from './Assets/ahmadraza.webp';
import gauhar_image from './Assets/gohar.webp';
import usmanjaved_image from "./Assets/usmanjaved-image.webp";
import sana_image from './Assets/sana1.webp';
import haleema_image from './Assets/haleema.webp';
import sarvar_image from './Assets/sarvar.webp';
import abdulqadeer_image from './Assets/qadeer.webp';
import imran_image from './Assets/imran.webp';

function App() {
  const facultyMembers = [
    {
      id: 1,
      name: "Muhammad Shahzad Sharif",
      position: "Manager Outreach & Coordination",
      facultyDepartment: "Administrative Staff",
      image: shahzad_image,
      email: "shahzad.sharif@lsituoe.edu.pk",
      url : 'Muhammad.Shahzad.Sharif',
      insitute: "Lahore School of Innovation and Technology",
      education:
        "Master of Science in Management Science (Supply Chain Management). National College of Business Administration & Economics Pakistan.",
      experience:
        "Manager Outreach & Coordination. Lahore School of Innovation & Technology, From 2021 till date.",
      about:
        "He is serving as a catalyst for team success through strategic leadership and fostering a collaborative work environment.",
    },
    {
      id: 2,
      name: "Humaira Fatima",
      position: "Marketing Executive",
      facultyDepartment: "Administrative Staff",
      image: humaira_image,
      email: "humaira.fatima@lsituoe.edu.pk",
      url: 'Humaira.Fatima',
      insitute: "Lahore School of Innovation and Technology",
      education:
        "Bachelors in Business Administration. University of Education, Lahore Pakistan.",
      experience:
        "Marketing Executive. Lahore School of Innovation & Technology, From Oct 2021 till date.",
      about:
        "A dynamic Marketing Executive, adept at crafting impactful campaigns and leveraging market trends to drive Institute success with creativity and strategic insight.",
    },
    {
      id: 3,
      name: "Ahsan Ameen",
      position: "Manager Accounts",
      facultyDepartment: "Administrative Staff",
      image: ahsan_image,
      email: "ahsan.ameen@lsituoe.edu.pk",
      url: 'Ahsan.Ameen',
      insitute: "Lahore School of Innovation and Technology",
      education:
        "Master of Business Administration - Finance. University of Gujrat, Pakistan.",
      experience:
        "Manager Accounts. Lahore School of Innovation and Technology, From 2019 till date.",
      about:
        "Articulate communicator with the ability to conceptualize and communicate vision, motivate, empower management, and influence decisions for the achievement of common goals.",
    },
    {
      id: 4,
      name: "Ali Jawad",
      position: "Director/Management Consultant",
      facultyDepartment: "Academic Faculty",
      image: aliJawad_image,
      email: "ali.jawad@lsituoe.edu.pk",
      url: 'Ali.Jawad',
      insitute: "Lahore School of Innovation and Technology",
      subjects: [
        "Management of A Technical Enterprise",
        "Strategy for Successful Business",
        "Technology Management",
        "Total Quality Management",
        "Organisational Behavior",
      ],
      interest: [
        "Management Sciences",
        "Organizational Behaviors",
        "Modern Technology",
        "Strategic Business Research",
      ],
      about:
      "Visionary and result-oriented Director/Management Consultant, Mr. Ali Jawad brings transformative leadership, strategic foresight, and a wealth of experience to drive organizational growth and excellence. His core competencies include analyzing and solving organizational challenges, providing expert advice on areas such as strategic operations, and efficiency, offering recommendations, and implementing solutions to optimize processes to attain institutional objectives."
    },
    {
      id: 5,
      name: "Umar Khan",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: umer_image,
      email: "umar.khan@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Umer.Khan',
      subjects: [
        "Business Ethics",
        "Human Resource Management",
        "Organizational Behavior",
        "Business Research Methods",
        "Introduction to Social Sciences",
        "Introduction to Business",
      ],
      interest: [
        "Human Resource Management",
        "HR Strategies",
        "POM",
        "Strategic Management",
        "Business Policies",
        "Conflict Management",
        "Change Management",
        "International Business Environment & Corporate Governance ",
      ],
      about:
        "A lifelong learner and have proven success record in teaching, research and human resource department, with effective facilitation, presentation, managerial and communication skills. ",
    },
    {
      id: 6,
      name: "Muhammad Ammad Arshad",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: ammad_image,
      email: "ammad.arshad@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Muhammad.Ammad.Arshad',
      subjects: [
        "Introduction to Business",
        "Financial Management",
        "Cost and Management Accounting",
        "Fundamentals of Accounting",
        "Business Finance",
        "Money & Banking",
        "Marketing Research",
        "Brand Management",
      ],
      interest: [
        "Supply Chain Management",
        "Entrepreneurship",
        "International Business",
        "Logistics Management",
        "Inventory Management",
        "Management Sciences",
        "Business Administration",
      ],
      about:
        "A dedicated educator specializing in business subjects, serves not only as an invaluable source of knowledge but also as a proactive and caring steward of the institute, skillfully managing various aspects with a commitment to its overall success and well-being.",
    },

    {
      id: 7,
      name: "Haider Ali Khan",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: haider_image,
      email: "haider.ali@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Haider.Ali.Khan',
      subjects: [
        "Marketing Management",
        "Enterprise Resource Planning (ERP)",
        "Principles of Management",
        "Introduction to Business",
      ],
      interest: [
        "Business Administration",
        "Information Technology",
        "Information Systems",
        "Accounting",
        "Financial Management",
      ],
      about:
        "A versatile educator specializing in Business Informatics, integrates Marketing, Accounting, and ERP (SAP) subjects, stands as a well-wisher to the institution, seamlessly blending expertise with genuine care to nurture students and contribute to the academic excellence of the learning community.",
    },
    {
      id: 8,
      name: "Zeeshan Naeem",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: zeeshan_image,
      email: "zeeshan.naeem@lsituoe.edu.pk",
      url: 'Zeeshan.Naeem',
      insitute: "Lahore School of Innovation and Technology",
      subjects: ["Electronics", "Digital Logic and Design", "Programming"],
      interest: ["Controls and Automation"],
      about: "A dynamic Lecturer at Lahore School of Innovation and Technology excels in teaching Calculus, Statistics, and related subjects. His passion for  Digital Electronics & Design, Controls, Automation, and Programming is evident in his commitment to fostering a deep understanding among students through dedicated research and dynamic teaching.",
    }, 
    {
      id: 9,
      name: "Bilal Talib",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: bilal_image,
      email: "bilal.talib@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Bilal.Talib',
      subjects: [
        "Principles of Microeconomics",
        "Principles of Macroeconomics",
        "Principles of Microeconomics",
        "Business Statistics",
        "Introduction to Statistics and Probability",
      ],
      interest: [
        "Statistics",
        "Economics",
      ],
      about:
      "Lecturer at Lahore School of Innovation and Technology, excels in teaching Principles of Microeconomics, Principles of Macroeconomics, Business Statistics, and Introduction to Statistics and Probability. His research interests lie in the fields of Statistics and Economics."
    },
    {
      id: 10,
      name: "Rajja Rashad",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: rajja_image,
      email: "raja.rashad@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Rajja.Rashad',
      subjects: [
        "Operations & Supply Chain Management",
        "Research Methods for Business",
        "Principles of Marketing",
        "Principles of Management",
        "Contemporary Business Studies",
        "Introduction to Business",
        "Insurance & Risk Management",
        "Business Communication",
      ],
      interest: [
        "Supplier Selection and Supply Chain Performance",
        "Industry 4.0 and Supply Chain Sustainability",
        "Business Sustainability Management",
      ],
      about:
        "A passionate educator specializing in business subjects, combines expertise with innovative teaching methods, fostering a dynamic and engaging learning environment to empower students with practical knowledge and critical thinking skills.",
    },
    {
      id: 11,
      name: "Dua Mahmood",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: dua_image,
      email: "dua.mahmood@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Dua.Mahmood',
      subjects: [
        "Programming Fundamentals",
        "Object Oriented Programming",
        "Operating Systems",
        "Management Information Systems",
        "Introduction to Information Technology",
        "Web Engineering",
        "Computer Networks",
        "Human-Computer Interaction",
      ],
      interest: [
        "Intersection of Robotics and Computer Vision",
        "Advanced algorithms for enhanced perception",
        "Application-driven solutions for real-world challenges",
        "Contribution to the advancement of intelligent and efficient robotic systems",
        "Leveraging a background in Electrical Engineering for a holistic approach",
        "Ph.D. pursuit in Computer Science with a commitment to pushing knowledge boundaries",
        "Propelling innovations in automation and artificial intelligence",
      ],
      about: "Ms. Dua Mehmood, an adept IT professional, imparts a robust curriculum covering Programming Fundamentals (PF), Object-Oriented Programming (OOP), Web Engineering, and many more subjects, fostering a well-rounded skill development for aspiring IT enthusiasts.",
    },
    {
      id: 12,
      name: "Asma Khalid ",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: asma_image,
      email: "asma.khalid@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Asma.Khalid',
      subjects: [
        "Educational Assessment ",
        "Curriculum Design & Instructions",
        "Science of Society",
        "General Methods of Teaching",
      ],
      interest: ["Educational Assessment", "Teaching Models in Education"],
      about:
        "I intend to improve my teaching skills and publish articles in the field of Education.",
    },
    {
      id: 13,
      name: "Dr. Ahmad Raza",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: ahmad_image,
      email: "ahmad.raza@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Dr.Ahmad.Raza',
      subjects: [
        "Islamic Studies",
        "Pakistan Studies",
        "Arabic",
        "Hadees",
        "Fiqa",
      ],
      interest: [
        "Religions",
        "Relationships in Islam",
        "Comparative Studies",
      ],
      about:
      "A distinguished expert in Islam, combines scholarly knowledge with a deep understanding of Islamic teachings, enriching students and communities through his expertise."
    },
    {
      id: 14,
      name: "Gauhar Siddique",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: gauhar_image,
      email: "gauhar.siddique@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Gauhar.Siddique',
      subjects: [
        "Elementary Linear Algebra",
        "Calculus - I",
        "Introduction to Statistics and Probability",
        "Multivariable Calculus",
        "Differential Equations",
        "Numerical Computing",
      ],
      interest: [
        "Statistics and Mathematics",
      ],
      about:
      "A dynamic Lecturer at Lahore School of Innovation and Technology, specializes in teaching Calculus, Statistics, and related subjects. His passion for Statistics and Mathematics is evident in his commitment to fostering a deep understanding among students through dedicated research and dynamic teaching."
    },
    {
      id: 15,
      name: "Usman Javed Sheikh ",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: usmanjaved_image,
      email: "usman.javed@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Usman.Javed.Sheikh',
      subjects: [
        "Artificial intelligence",
        "Machine Learning",
        "Deep learning",
        "Distributed System",
        "Cloud Computing",
        "IT Project Management",
      ],
      interest: [
        "Artificial intelligence",
        "Machine learning",
        "Distributed System",
        "Deep Learning",
        "Cloud Computing",
      ],
      about:
      "An expert educator, imparts knowledge in cutting-edge subjects such as Artificial Intelligence and Machine Learning, providing students with a comprehensive understanding of these transformative technologies."
    },
    {
      id: 16,
      name: "Sana Yaqoob",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: sana_image,
      email: "sana.yaqoob@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Sana.Yaqoob',
      subjects: [
        "Object Oriented Programming",
        "Data Analysis and Design ",
        "Programming Fundamentals",
        "Data Structure and Algorithms",
        "Elementary Linear Algebra",
        "Human-Computer Interaction",
      ],
      interest: [
        "Machine Learning",
        "Artificial Intelligence",
        "DJANGO Framework",
        "Python",
        "Andriod Studio"
      ],
      about:
        "A researcher in the field of computer science, currently working on machine learning, python development, review journal and conference articles, thesis paper proofreading, and research-based experiments.",
    },
    {
      id: 17,
      name: "Haleema Muqarrab",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: haleema_image,
      email: "haleema.muqarrab@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Haleema.Muqarrab',
      subjects: [
        "Introduction to Computing",
        "Database",
        "Programming Fundamentals",
        "OOP"
      ],
      interest: [
        "Real-time violence recognition using deep learning",
        "Spondolysis Detection",
        "Using AI to predict the best food choices for gout patients",
      ],
      about:
        "Ms. Haleema Muqarrab, an experienced educator, specializes in delivering comprehensive insights into Object-Oriented Programming (OOP), and Database subjects, providing students with a well-rounded understanding of these key domains.",
    },
    {
      id: 20,
      name: "Eng. Muhammad Sarwar",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: sarvar_image,
      email: "muhammad.sarwar@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Eng.Muhammad.Sarwar',
      subjects: [
        "Network Security",
        "Computer Network",
      ],
      interest: [
        "IT Networks",
        "Cloud Security",
        "Security Automation",
        "Human Factor in Network Security",
      ],
      about:
      "An accomplished educator, who specializes in teaching subjects such as Network Security and Computer Networks, combining practical expertise to equip students with essential skills in these critical areas of information technology."
    },
    {
      id: 21,
      name: "Abdul Qadeer",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: abdulqadeer_image,
      email: "abdul.qadeer@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Abdul.Qadeer',
      subjects: [
        "Everyday Science",
        "Social Science",
      ],
      interest: [
        "Social Sciences",
        "Study of Society",
      ],
      about:
      "An engaging educator, who imparts knowledge in Everyday Science and Social Studies, fostering a holistic understanding of the world and its scientific and societal aspects among students."
    },
    {
      id: 22,
      name: "Imran Zahoor",
      position: "Lecturer",
      facultyDepartment: "Academic Faculty",
      image: imran_image,
      email: "imran.zahoor@lsituoe.edu.pk",
      insitute: "Lahore School of Innovation and Technology",
      url: 'Imran.Zahoor',
      subjects: [
        "Internation Relations",
        "Ideology and Constitutions",
        "Pakistan Studies",
        "Islamic Studies",
        "Current Affairs",
      ],
      interest: [
        "Climate Change",
        "Women's Security",
        "Middle East",
        "Afghanistan Issue",
      ],
      about:
        "Imran Zahoor, an insightful educator, specializes in teaching International Relations, Ideology, and Pakistan Studies, providing students with a comprehensive understanding of key subjects related to global affairs and the national context."
    },
    
       
  ];
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bscs-details" element={<BSCS />} />
        <Route path="/bsit-details" element={<BSIT />} />
        <Route path="/bba-details" element={<BBA />} />
        <Route
          path="/administrative-staff"
          element={<AdministrativeStaff facultyMembers={facultyMembers} />}
        />
        <Route
          path="/academic-faculty"
          element={<AcademicFaculty facultyMembers={facultyMembers} />}
        />
        <Route
          path="/:url"
          element={<FacultyDetails facultyMembers={facultyMembers} />}
        />
        <Route path="/scholarships" element={<Scholarships/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>
    </>
  );
}

export default App;
