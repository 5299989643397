import React from 'react'
import './Footer.css'
function Footer() {
    return (
        <>
    <div className="footer">
        <div className="footer-container">
            <div className="footer-row">                

            <div className="footer-column">
                    <h5>CONTACT US</h5>
                    <p><a href="tel:+923171777355" target='blank'><i className="fa-solid fa-phone-volume"></i> Phone: +92 3171777355</a></p>
                    <p><a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=lsituoe@gmail.com" target='blank'><i className="fa-solid fa-envelope"></i> Email: lsituoe@gmail.com</a></p>
                    <p><a href="https://maps.app.goo.gl/Ewk899HYWu7gcVgS7" target='blank'><i className="fa-solid fa-location-dot"></i> 35 A/1 Government Employees Housing Society,<br/> Maulana Shaukat Ali Rd,<br/>Township Sector A1 Lahore, Punjab 54000.</a></p>
                </div>

                <div className="footer-column">
                    <h5>USEFUL LINKS</h5>
                    <ul>
                        <li className="nav-items">
                        <a className="" href="https://docs.google.com/forms/d/e/1FAIpQLSfDCFhTHjuw2zEW8uQ9B3F7MBg4dJ3zsl0c3VYSlYb9_XbPjg/viewform?usp=sf_link" target='blank'> Apply Online</a>
                        </li>
                        <li className="nav-items">
                        <a className="" href='/scholarships'> Scholarships</a>
                        </li>
                        <li className="nav-items">
                        <a className="" href="https://lsit.effii.app/portal/index.php" target='blank'> Student Portal</a>
                        </li>
                        <li className="nav-items">
                        <a className="" href="https://lsit.effii.app/admin/index.php" target='blank'> Admin Portal</a>
                        </li>
                        <li className="nav-items">
                        <a className="" href="https://drive.google.com/drive/folders/1PXzxOVL9I0TvZADSuKVlImKNGV1RVucR?usp=sharing" target='blank'>LSIT-UE Affiliation</a>
                        </li>
                    </ul>
                </div>
            
                <div className="footer-column">
                    <h5>FOLLOW US</h5>
                    <div className="footer-icons">
                        <a href="https://www.facebook.com/bbabsitLsituoe" target='blank'><i className="fa-brands fa-facebook"></i></a>
                        <a href="https://www.instagram.com/lsituoe/?next=%2F" target='blank'><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/lahore-school-of-innovation-technology/mycompany/" target='blank'><i className="fa-brands fa-linkedin-in"></i></a>
                        <div className="footer-icons">
                        <a href="https://www.tiktok.com/@lsitue1?_t=8jBEMYBhLbW&_r=1" target='blank'><i className="fa-brands fa-tiktok"></i></a>
                        <a href={`https://chat.whatsapp.com/KaT8Z1hemwgH2zOW6DnXRR`} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </div>
    <div className='Last-footer'>
        <hr/>
        <p>Copyright © 2024 - 2025 Lahore School of Innovation and Technology</p>
    </div>
</>
  )
}

export default Footer
