import React from 'react'
import './About.css'

function About() {
  return (
    <div className='about-container'>
        <div className="about-main-banner">
            <h1>About LSIT</h1>
        </div>

        <div className="about-sub-content">
            <h2>LSIT Your Strategic Gateway</h2>
            <p>Embrace LSIT as your strategic gateway to unleash your potential in an environment meticulously designed to foster academic excellence, hands-on learning, and industry. Relevance, and personal growth. By embarking on this transformative Journey with us, you will forge a solid foundation for a future brimming with success and fulfillment.</p>
        </div>
        <div className="about-sub-content">
            <h2>Vision</h2>
            <p>To lead and shape the educational and economic progress of our country by offering job-oriented and hi-tech degrees, which focus on developing professional skills in students.</p>
        </div>

        <div className="about-sub-content">
            <h2>Mission</h2>
            <p>We, at LSIT, aim to impart innovation, creativity, and professionalism competency in students at affordable fees in a purpose-built environment. Our students are empowered to fulfill their academic and professional ardency to work for the betterment of this society, as a whole.</p>
        </div>
        
        <div className="about-sub-content">
            <h2>Student-Centered Training Approach</h2>
            <p>At LSIT, teaching staff adopts a student-centered approach to increase opportunities for student engagement, which then helps every student to more successfully achieve the course's learning objectives. This aim is accomplished through Cooperative learning, in which students work in teams on problems and projects under conditions that assure both positive interdependence and individual accountability Students are given opportunities to lead learning activities, participate more actively in discussions, design their own learning projects, explore topics that interest them, and generally contribute to the design of their own course of study.</p>
        </div>
        <div className="about-sub-content">
            <h2>Focus on Life Skills</h2>
            <p>We at LSIT try our best to internalize in our students the qualities of head and heart. Free personality development opportunities are offered to LSIT students such that they are able to demonstrate their technical competence in a presentable manner in the practical world.</p>
        </div>
        <div className="about-sub-content">
            <h2>Diversity, Equity, and Inclusion</h2>
            <p>We value all dimensions of diversity and seek to model the fairness and justice that we want to see in the world and because of the work that we do, we place a particular focus on reflecting the students of low-income communities most impacted by our work.</p>
        </div>

    </div>
  )
}

export default About
