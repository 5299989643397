import React, { useState }from 'react'
import logo from '../../Assets/lsit_logo.webp'
import './NavBar.css'

function NavBar() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
    <div className="navbar">
      <div className="nav-logo">
        <a href='/' className='nav-logo'>
          <img src={logo} alt="logo" />
          <p>Lahore School of Innovation and Technology</p>
        </a>
      </div>

      <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
        ☰
      </button>

      <ul className={`nav-menu ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <li className="nav-item"><a href='/'>Home</a></li>
        <li className="nav-item">Academics
          <ul className="submenu">
            <li><a href="/bscs-details">Bachelor of Computer Science (BSCS)</a></li>
            <li><a href="/bsit-details">Bachelor of Information Technology (BSIT)</a></li>
            <li><a href="/bba-details">Bachelor of Business Administration (BBA)</a></li>
          </ul>
        </li>
        <li className="nav-item">Admissions
          <ul className="submenu">
            <li><a href='https://docs.google.com/forms/d/e/1FAIpQLSfDCFhTHjuw2zEW8uQ9B3F7MBg4dJ3zsl0c3VYSlYb9_XbPjg/viewform?usp=sf_link' target='blank'>Apply Online</a></li>
            <li><a href='/scholarships'>Scholarships</a></li>
          </ul>
        </li>
        <li className="nav-item">Portals
          <ul className="submenu">
            <li><a href="https://lsit.effii.app/portal/index.php" target='blank'>Student Portal</a></li>
            <li><a href="https://lsit.effii.app/admin/index.php" target='blank'>Teacher Portal</a></li>
            <li><a href="http://lms.ue.edu.pk/" target='blank'>UE Student Portal</a></li>
          </ul>
        </li>
        <li className="nav-item">Faculty
          <ul className="submenu">
            <li><a href='/administrative-staff'>Administrative Staff</a></li>
            <li><a href='/academic-faculty'>Academic Faculty</a></li>
          </ul>
        </li>
        <li className="nav-item"><a href='/about'>About</a></li>
      </ul>
    </div>
    </>
  )
}

export default NavBar
