import React from 'react'
import './WhyLsit.css';
function WhyLsit() {
  return (
      <div className="whyLsit_container">
      <h1>Why LSIT ?</h1>
      <div className='paragraph'>
        <p>
        Lahore School of Innovation and Technology (LSIT) stands out as a leading
        educational institution affiliated with the esteemed University of
        Education (UE). Specializing in Management & Information technology, LSIT is committed to
        delivering a comprehensive learning experience that combines theoretical
        knowledge with practical skills. The institute boasts a cutting-edge
        campus and a seasoned faculty, fostering an environment that encourages
        innovation and critical thinking among students.<br/>
        </p>
      </div>
      <div className='paragraph'>
        <p>
        In choosing Lahore School of Innovation and Technology (LSIT), students embrace a unique educational experience that goes beyond traditional learning methods. The institute's commitment to innovation and technology is evident in its curriculum, which is tailored to equip students with the skills and knowledge needed to thrive in a rapidly evolving technological landscape. LSIT's emphasis on practical, hands-on learning sets it apart, preparing students for real-world challenges and ensuring they are well-prepared for the demands of the professional sphere.<br/>
        </p>
      </div>
      <div className='paragraph'>
        <p>
        Aligned with the University of Education (UE), LSIT ensures academic rigor and quality,
        providing students with a recognized qualification that prepares them
        for success in the dynamic field of information technology. With a focus
        on shaping future IT leaders, Lahore School of Innovation and Technology (LSIT) stands as a hub for excellence, equipping students with the expertise needed to navigate and contribute
        to the evolving landscape of technology.
        </p>
      </div>
      </div>
    )
}

export default WhyLsit
