import React from "react";
import "./ChairmanMsg.css";
import directorImg from "../../Assets/director_image_1.webp";
function ChairmanMsg() {
  return (
    <div className="chairmanMsg_container">
      <div className="top-content">
        <h1>Director's Message</h1>
      </div>

      <div className="chairmanMsg">
        <div className="directorImage">
          <img src={directorImg} alt="" />
        </div>

        <div className="bottom-content">
          <p>
            "As Director LSIT, I am committed to strengthening programs for faculty and student development, prioritizing service to the developing world. Our mission is to be a distinguished institution, disseminating knowledge on core principles: quality, access, impact, and relevance.
            We aim to foster vitality in Management Sciences & Information Technology through teaching, research, and outreach, ultimately achieving our vision. LSIT offers an exciting platform for refining your capabilities, anticipating a harmonious relationship, and preparing you for the world's challenges."
          </p>
          <div className="details">
          <h2>Ali Jawad</h2>
          <h6>Director, LSIT</h6>
          </div>
      </div>
    </div>
    </div>
  );
}

export default ChairmanMsg;
