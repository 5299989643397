import React, { useState, useEffect, useRef } from 'react';
import "./AboutLsit.css";
import undergraduate_programs from "../../Assets/Undergraduate-Programs-Offered.webp";
import faculity_members from "../../Assets/PhD-Programs-Offered.webp";
import student_teacher_ratio from "../../Assets/Student-Teacher-Ratio.webp";
import alumni from "../../Assets/Alumni.webp";
import women_in_faculity from "../../Assets/Women-in-Faculty.webp";
import female_students_population from "../../Assets/Female-Students-Population.webp";
import scholarship_awarded from "../../Assets/Scholarships-Awarded.webp";
import students_on_financialaid from "../../Assets/Students-on-Financial-Aid-1.webp";
import students_society from "../../Assets/Dynamic-Student-Clubs-Societies.webp";

function ValueCounter({ initialValue, maxValue, intervalTime }) {
    const [value, setValue] = useState(initialValue);
    const [inViewport, setInViewport] = useState(false);
    const intervalRef = useRef(null);
    const counterRef = useRef(null);
  
    const handleIntersection = (entries) => {
      const entry = entries[0];
      setInViewport(entry.isIntersecting);
    };
  
    useEffect(() => {
      const increaseValue = () => {
        if (value < maxValue && inViewport) {
          setValue((prev) => prev + 1);
        }
      };
  
      intervalRef.current = setInterval(increaseValue, intervalTime);
  
      return () => {
        clearInterval(intervalRef.current);
      };
    }, [value, maxValue, intervalTime, inViewport]);
  
    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5, // Adjust as needed // means 50% on viewport
      };
  
      const observer = new IntersectionObserver(handleIntersection, options);
  
      if (counterRef.current) {
        observer.observe(counterRef.current);
      }
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    return (
      <span ref={counterRef}>{value}</span>
    );
  }
  
function AboutLsit() {
  return (
    <div className="aboutWrapper">
        <div className="aboutText">
            <div className="heading">
                <h1>About LSIT</h1>
            </div>
            <div className="wrapperSection">
                <div className="row">
                   <div className="column">
                    <div className="innerText">
                        <div className="top_icon">
                            <img src={undergraduate_programs} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={3} intervalTime={1000} />
                        </div>
                        <h3>Undergraduate Programs Offered</h3>
                    </div>
                   </div>
                   <div className="column">
                   <div className="innerText">
                        <div className="top_icon">
                            <img src={faculity_members} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={25} intervalTime={120} />+
                        </div>
                        <h3>Faculty Members</h3>
                    </div>
                   </div>
                   <div className="column">
                   <div className="innerText">
                        <div className="top_icon">
                            <img src={student_teacher_ratio} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={30} intervalTime={100} />:1
                        </div>
                        <h3>Student Teacher Ratio</h3>
                    </div>
                    </div> 
                </div>
            </div>
            <div className="wrapperSection">
            <div className="row">
                   <div className="column">
                    <div className="innerText">
                        <div className="top_icon">
                            <img src={alumni} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={70} intervalTime={42.8571428571} />+
                        </div>
                        <h3>Alumni</h3>
                    </div>
                   </div>
                   <div className="column">
                   <div className="innerText">
                        <div className="top_icon">
                            <img src={women_in_faculity} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={41} intervalTime={73.17073171} />%
                        </div>
                        <h3>Women in Faculty</h3>
                    </div>
                   </div>
                   <div className="column">
                   <div className="innerText">
                        <div className="top_icon">
                            <img src={female_students_population} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={46} intervalTime={65.2173913043} />%
                        </div>
                        <h3>Female Students Population</h3>
                    </div>
                    </div> 
                </div>
            </div>
            <div className="wrapperSection">
            <div className="row">
                   <div className="column">
                    <div className="innerText">
                        <div className="top_icon">
                            <img src={scholarship_awarded} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={371} intervalTime={2.960556845} />K+
                        </div>
                        <h3>Scholarships Awarded</h3>
                    </div>
                   </div>
                   <div className="column">
                   <div className="innerText">
                        <div className="top_icon">
                            <img src={students_on_financialaid} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={53} intervalTime={56.6037735849} />%
                        </div>
                        <h3>Students on Financial Aid</h3>
                    </div>
                   </div>
                   <div className="column">
                   <div className="innerText">
                        <div className="top_icon">
                            <img src={students_society} alt="" />
                        </div>
                        <div className="count">
                        <ValueCounter initialValue={0} maxValue={3} intervalTime={1000} />+
                        </div>
                        <h3>Dynamic Student Clubs/Societies</h3>
                    </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
  );
}

export default AboutLsit;
