import './ImgSlider.css';
import main_image from '../../Assets/main_image.webp';
import office_image from '../../Assets/main_image2.webp';
import ground_image2 from '../../Assets/ground_image2.webp';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function ImgSlider() {
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        pauseOnHover: false,
      };
  return (
    <Slider className='img-container' {...settings}>
      <div className="wrap">
        <img src={main_image} alt='lsit_image'/>
      </div>
      <div className="wrap">
        <img src={office_image} alt='lsit_image'/>
      </div>
      <div className="wrap">
        <img src={ground_image2} alt='lsit_image'/>
      </div>
    </Slider>
  )
}

export default ImgSlider
