import logo from '../../Assets/lsit_logo.webp'
import { useParams } from 'react-router-dom';
import './FacultyDetails.css';

const FacultyDetails = ({ facultyMembers }) => {
  const { url } = useParams();
  const member = facultyMembers.find((member) => member.url === url);

  if (!member) {
    return <div>Member not found</div>;
  }

  const isAcademicFaculty = member.facultyDepartment === 'Academic Faculty';
  const isAdministrativeStaff = member.facultyDepartment === 'Administrative Staff';

  return (
    <div className="faculty-details-container">
      <div className="university-header">
        {/* University Logo */}
        <div className="university-logo">
          <img src={logo} alt="University Logo" />
        </div>
        {/* University Headings */}
        <div className="university-headings">
          <h1>Faculty Profile</h1>
          <p>Lahore School of Innovation and Technology</p>
        </div>
      </div>

      <div className="faculty-details">
        {/* Faculty Member Image */}
        <div className="faculty-details-left">
          <img src={member.image} alt="Faculty Member" />
        </div>

        {/* Faculty Member Details */}
        <div className="faculty-details-right">
          <h1>{member.name}</h1>
          <p>{member.position}</p>
          <p>{member.insitute}</p>
          <p><a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${member.email}`}target='blank'>{member.email}</a></p>
          </div>
        </div>

          {/* Conditionally render Subjects Taught for Management Sciences */}
          <div className="extra-faculty-details">
          {isAcademicFaculty && (
            <div className="subjects-taught">
              <h1>Subjects Taught</h1>
              <ul>
                {member.subjects && member.subjects.map((subject, index) => (
                  <li key={index}>{subject}</li>
                ))}
              </ul>
            </div>
          )}

          {isAcademicFaculty && (
            <div className="area-of-interest">
              <h1>Research Interest</h1>
              <ul>
                {member.interest && member.interest.map((interest, index) => (
                  <li key={index}>{interest}</li>
                ))}
              </ul>
            </div>
          )}

          {isAcademicFaculty && (
            <div className="about">
              <h1>About</h1>
              <p>{member.about}</p>
            </div>
          )}

          {isAdministrativeStaff && (
            <div className="about">
              <h1>Education </h1>
              <p>{member.education}</p>
            </div>
          )}

          {isAdministrativeStaff && (
            <div className="about">
              <h1>Experience</h1>
              <p>{member.experience}</p>
            </div>
          )}

          {isAdministrativeStaff && (
            <div className="about">
              <h1>About</h1>
              <p>{member.about}</p>
            </div>
          )}
      </div>
    </div>
  );
};

export default FacultyDetails;
